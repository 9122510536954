'use client';

import * as React from 'react';
import { FC, ReactNode } from 'react';
import {
  DehydratedState,
  HydrationBoundary,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getQueryClient } from '../api/queryClient';
import { useTranslations } from 'next-intl';

interface Props {
  children: ReactNode;
  dehydratedState?: DehydratedState;
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/production').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

export const CustomQueryClientProvider: FC<Props> = ({
  children,
  dehydratedState,
}) => {
  const errorsTranslations = useTranslations('errors');
  const queryClient = getQueryClient(errorsTranslations);

  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error kek-dev
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};
